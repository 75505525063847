import React from 'react'

import './../scss/PageHero.scss'

function PageHero() {
  return (
    <div className="page-hero">
      <div className='page-hero__content constrain-content'>
        <h1 className='main-logo'>
          <span className='sr-only'>ION Mystery</span>
        </h1>
        <div className='page-hero__description'>
          Put on your detective hat and join the investigation with ION Mystery! With top-rated shows, including “CSI: Miami,” “Bones,” and "NCIS: New Orleans," ION Mystery is home to television’s most binge-worthy, edge-of-your-seat thrillers.
        </div>
      </div>
    </div>
  )
}

export default PageHero