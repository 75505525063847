import React from 'react'
import { useQuery } from 'react-query'
import FindUs from '../components/FindUs'
import Schedule from '../components/Schedule'
import PageHero from './../components/PageHero'
import Working from '../components/Working'
import ViewingPlatforms from '../components/ViewingPlatforms'

const getAffiliates = async () => {
    const getAffiliates = async (endpoint, at) => {
        let response = await fetch(endpoint)
  
        return response.json()
    }
    // initialize data
    let endpoint = `${process.env.REACT_APP_AFFILIATES}`

    const results = await getAffiliates(endpoint)
    
    return results
}


const getPlatforms = async () => {
    let endpoint = `${process.env.REACT_APP_PLATFORMS}`
    let response = await fetch(endpoint)
    let results = await response.json()
    
    return results[0].viewing_platforms
}

function Home() {
    const { isSuccess, isLoading, isError, data: affiliates } = useQuery([`affiliates`, {resourceName: 'affiliates',}], getAffiliates)
    const { isSuccess: isSuccessPlatforms, data: platforms } = useQuery([`platforms`, {resourceName: 'platforms',}], getPlatforms)

    return (<>
        <PageHero />
        <div className='mobile-hero-description'>
            Put on your detective hat and join the investigation with ION Mystery! With top-rated shows, including “CSI: Miami,” “Bones,” and "NCIS: New Orleans," ION Mystery is home to television’s most binge-worthy, edge-of-your-seat thrillers.
        </div>
        <Schedule />
        <h2 className='section-header' id="find-us">Find ION Mystery</h2>
        {isLoading && <div style={{'height': '30rem'}}><Working /></div>}
        {isError && <div className="constrain-content">We're sorry we aren't able to find our station data right now. Please check back soon.</div>}
        {isSuccess &&             
            <FindUs 
                affiliates={affiliates} 
                network={'ION Mystery'} 
                hideSelectOnResults={false}
                autoScrollOffset={0}
            />
        }
        {isSuccessPlatforms && <ViewingPlatforms platforms={platforms} />}
    </>)
}

export default Home