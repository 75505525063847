import React from 'react'
import {FaFacebookSquare, FaTwitterSquare, FaTiktok} from 'react-icons/fa'

import './../../scss/Footer.scss'

function Footer() {
  return (
    <footer className='main-footer'>
      <div className='constrain-content'>
        <nav className='footer-nav'>
          <ul className='footer-nav__list'>
            <li className="footer-nav__item"><a className='footer-nav__link' href="https://iontelevision.com">ION Television</a></li>
            <li className="footer-nav__item"><a className='footer-nav__link' href="https://iontelevision.com/privacy-policy">Privacy Policy</a></li>
            <li className="footer-nav__item"><a className='footer-nav__link' href="https://iontelevision.com/privacy-center?domain=ionmystery">Privacy Center</a></li>
            <li className="footer-nav__item"><a className='footer-nav__link' href="https://iontelevision.com/terms-of-use">Terms of Use</a></li>
            <li className="footer-nav__item"><a className='footer-nav__link' href="https://iontelevision.com/faqs">FAQs</a></li>
            <li className="footer-nav__item"><a className='footer-nav__link' href="https://support.ionmystery.com/">Contact</a></li>
            <li className="footer-nav__item">
              <a href="https://www.facebook.com/ionmystery/" className='footer-nav__social-link' target="_blank" rel="noreferrer">
                <FaFacebookSquare/><span className='sr-only'>Visit Us on Facebook</span>
              </a>
              &nbsp;
              <a href="https://mobile.twitter.com/ionmystery" className='footer-nav__social-link' target="_blank" rel="noreferrer">
                <FaTwitterSquare/><span className='sr-only'>Visit us on Twitter</span>
              </a>
              &nbsp;
              <a href="https://www.tiktok.com/@ionmystery?lang=en" className='footer-nav__social-link' target="_blank" rel="noreferrer">
                <FaTiktok/><span className='sr-only'>Visit us on TikTok</span>
              </a>
            </li>
          </ul>
        </nav>
        <div className='copyright'>
          All content, images, media and associated materials are property of ION Media Networks. © 2022 ION Media Networks, all rights reserved.
        </div>
      </div>
    </footer>
  )
}

export default Footer